import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Section from "../components/section"

export const imageSettings = graphql`
  fragment imageSettings on File {
    childImageSharp {
      fluid(maxWidth: 960) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export default () => {
  const data = useStaticQuery(graphql`
    query {
      a: file(relativePath: { eq: "smartstyle/1.jpg" }) {
        ...imageSettings
      }
      b: file(relativePath: { eq: "smartstyle/2.jpg" }) {
        ...imageSettings
      }
    }
  `)

  return (
    <Section>
      <a href="https://www.smartstyle.com/">
        <h2>Smart Style Salon</h2>
      </a>
      <div className="ImageWrapper ImageGrid">
        <Img fluid={data.a.childImageSharp.fluid} />
        <Img fluid={data.b.childImageSharp.fluid} />
      </div>
    </Section>
  )
}
