import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Section from "../components/section"

export const imageSettings = graphql`
  fragment imageSettings on File {
    childImageSharp {
      fluid(maxWidth: 960) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export default () => {
  const data = useStaticQuery(graphql`
    query {
      a: file(relativePath: { eq: "billy/028.jpg" }) {
        ...imageSettings
      }
      b: file(relativePath: { eq: "billy/029.jpg" }) {
        ...imageSettings
      }
      c: file(relativePath: { eq: "billy/030.jpg" }) {
        ...imageSettings
      }
      d: file(relativePath: { eq: "billy/031.jpg" }) {
        ...imageSettings
      }
      e: file(relativePath: { eq: "billy/032.jpg" }) {
        ...imageSettings
      }
      f: file(relativePath: { eq: "billy/033.jpg" }) {
        ...imageSettings
      }
      g: file(relativePath: { eq: "billy/034.jpg" }) {
        ...imageSettings
      }
      h: file(relativePath: { eq: "billy/035.jpg" }) {
        ...imageSettings
      }
      i: file(relativePath: { eq: "billy/036.jpg" }) {
        ...imageSettings
      }
      j: file(relativePath: { eq: "billy/037.jpg" }) {
        ...imageSettings
      }
      k: file(relativePath: { eq: "billy/038.jpg" }) {
        ...imageSettings
      }
      l: file(relativePath: { eq: "billy/039.jpg" }) {
        ...imageSettings
      }
      m: file(relativePath: { eq: "billy/040.jpg" }) {
        ...imageSettings
      }
      n: file(relativePath: { eq: "billy/041.jpg" }) {
        ...imageSettings
      }
      o: file(relativePath: { eq: "billy/042.jpg" }) {
        ...imageSettings
      }
      p: file(relativePath: { eq: "billy/043.jpg" }) {
        ...imageSettings
      }
      q: file(relativePath: { eq: "billy/044.jpg" }) {
        ...imageSettings
      }
      r: file(relativePath: { eq: "billy/045.jpg" }) {
        ...imageSettings
      }
      s: file(relativePath: { eq: "billy/046.jpg" }) {
        ...imageSettings
      }
      t: file(relativePath: { eq: "billy/047.jpg" }) {
        ...imageSettings
      }
      u: file(relativePath: { eq: "billy/048.jpg" }) {
        ...imageSettings
      }
    }
  `)

  return (
    <Section>
      <a href="https://www.youtube.com/watch?v=axHcpuqcY4c">
        <h2>Billy Ray Cyrus - Angel in my Pocket Music Video</h2>
      </a>
      <div className="ImageWrapper ImageGrid">
        <Img fluid={data.a.childImageSharp.fluid} />
        <Img fluid={data.b.childImageSharp.fluid} />
        <Img fluid={data.c.childImageSharp.fluid} />
        <Img fluid={data.d.childImageSharp.fluid} />
        <Img fluid={data.e.childImageSharp.fluid} />
        <Img fluid={data.f.childImageSharp.fluid} />
        <Img fluid={data.g.childImageSharp.fluid} />
        <Img fluid={data.h.childImageSharp.fluid} />
        <Img fluid={data.i.childImageSharp.fluid} />
        <Img fluid={data.j.childImageSharp.fluid} />
        <Img fluid={data.k.childImageSharp.fluid} />
        <Img fluid={data.l.childImageSharp.fluid} />
        <Img fluid={data.m.childImageSharp.fluid} />
        <Img fluid={data.n.childImageSharp.fluid} />
        <Img fluid={data.o.childImageSharp.fluid} />
        <Img fluid={data.p.childImageSharp.fluid} />
        <Img fluid={data.q.childImageSharp.fluid} />
        <Img fluid={data.r.childImageSharp.fluid} />
        <Img fluid={data.s.childImageSharp.fluid} />
        <Img fluid={data.t.childImageSharp.fluid} />
        <Img fluid={data.u.childImageSharp.fluid} />
      </div>
    </Section>
  )
}
