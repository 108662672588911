import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Section from "../components/section"

export const imageSettings = graphql`
  fragment imageSettings on File {
    childImageSharp {
      fluid(maxWidth: 960) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export default () => {
  const data = useStaticQuery(graphql`
    query {
      a: file(relativePath: { eq: "comics/FLoGT_1.jpg" }) {
        ...imageSettings
      }
      b: file(relativePath: { eq: "comics/FLoGT_ch3_pg_11.jpg" }) {
        ...imageSettings
      }
      c: file(relativePath: { eq: "comics/FLoGT_ch3_pg_14.jpg" }) {
        ...imageSettings
      }
      d: file(relativePath: { eq: "comics/FLoGT_ch3_pg_15.jpg" }) {
        ...imageSettings
      }
      e: file(relativePath: { eq: "comics/FLoGT_ch3_pg_18.jpg" }) {
        ...imageSettings
      }
    }
  `)

  return (
    <Section>
      <a href="https://mfruckus.com/home">
        <h2>MF Ruckus: The Front Lines of Good Times</h2>
      </a>

      <div className="ImageWrapper">
        <Img fluid={data.a.childImageSharp.fluid} />
      </div>

      <div className="ImageWrapper">
        <Img fluid={data.b.childImageSharp.fluid} />
      </div>

      <div className="ImageWrapper">
        <Img fluid={data.c.childImageSharp.fluid} />
      </div>

      <div className="ImageWrapper">
        <Img fluid={data.d.childImageSharp.fluid} />
      </div>

      <div className="ImageWrapper">
        <Img fluid={data.e.childImageSharp.fluid} />
      </div>
    </Section>
  )
}
