import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Section from "../components/section"

export const imageSettings = graphql`
  fragment imageSettings on File {
    childImageSharp {
      fluid(maxWidth: 960) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export default () => {
  const data = useStaticQuery(graphql`
    query {
      suspectPressCover: file(
        relativePath: { eq: "illustrations/suspect_press_cover.jpg" }
      ) {
        ...imageSettings
      }
      mfRuckusAlbumCover: file(
        relativePath: { eq: "illustrations/mf_ruckus_album_cover.jpg" }
      ) {
        ...imageSettings
      }
      denverDads: file(relativePath: { eq: "illustrations/denver_dads.jpg" }) {
        ...imageSettings
      }
      vaporwaveRuckus: file(
        relativePath: { eq: "illustrations/vaporwave_ruckus.jpg" }
      ) {
        ...imageSettings
      }
      mostlyHarmlessPodcast: file(
        relativePath: { eq: "illustrations/mostly_harmless_podcast.jpg" }
      ) {
        ...imageSettings
      }
      hailSatanCover: file(
        relativePath: { eq: "illustrations/hail_satan_cover.jpg" }
      ) {
        ...imageSettings
      }
      ruckusShowPoster: file(
        relativePath: { eq: "illustrations/ruckus_show_poster.jpg" }
      ) {
        ...imageSettings
      }
      villiansBookCover: file(
        relativePath: { eq: "illustrations/villians_book_cover.jpg" }
      ) {
        ...imageSettings
      }
    }
  `)

  return (
    <>
      <Section>
        <a href="https://www.suspectpress.com/issue-17/">
          <h2>Suspect Press - Cover</h2>
        </a>
        <div className="ImageWrapper">
          <Img fluid={data.suspectPressCover.childImageSharp.fluid} />
        </div>
      </Section>

      <Section>
        <h2>MF Ruckus - Album Cover</h2>
        <div className="ImageWrapper">
          <Img fluid={data.mfRuckusAlbumCover.childImageSharp.fluid} />
        </div>
      </Section>

      <Section>
        <a href="https://www.suspectpress.com/2018/07/25/denver-dads-2/">
          <h2>Suspect Press - Denver Dads</h2>
        </a>
        <div className="ImageWrapper">
          <Img fluid={data.denverDads.childImageSharp.fluid} />
        </div>
      </Section>

      <Section>
        <h2>Concert Poster</h2>
        <div className="ImageWrapper">
          <Img fluid={data.vaporwaveRuckus.childImageSharp.fluid} />
        </div>
      </Section>

      <Section>
        <h2>Mostly Harmless Podcast</h2>
        <div className="ImageWrapper">
          <Img fluid={data.mostlyHarmlessPodcast.childImageSharp.fluid} />
        </div>
      </Section>

      <Section>
        <h2>Hail Satan - Album Cover</h2>
        <div className="ImageWrapper">
          <Img fluid={data.hailSatanCover.childImageSharp.fluid} />
        </div>
      </Section>

      <Section>
        <h2>Show Poster</h2>
        <div className="ImageWrapper">
          <Img fluid={data.ruckusShowPoster.childImageSharp.fluid} />
        </div>
      </Section>

      <Section>
        <h2>80's Villians - Book Cover</h2>
        <div className="ImageWrapper">
          <Img fluid={data.villiansBookCover.childImageSharp.fluid} />
        </div>
      </Section>
    </>
  )
}
