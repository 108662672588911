import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import TIHM from "../components/TIHM"
import FLOGT from "../components/FLOGT"
import Billy from "../components/Billy"
import SmartStyle from "../components/SmartStyle"
import Illustrations from "../components/Illustrations"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />

    <TIHM />

    <FLOGT />

    <Billy />

    <SmartStyle />

    <Illustrations />
  </Layout>
)

export default IndexPage
