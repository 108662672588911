import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Section from "../components/section"

export const imageSettings = graphql`
  fragment imageSettings on File {
    childImageSharp {
      fluid(maxWidth: 960) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export default () => {
  const data = useStaticQuery(graphql`
    query {
      one: file(relativePath: { eq: "comics/TIHM_1.jpg" }) {
        ...imageSettings
      }
      two: file(relativePath: { eq: "comics/TIHM_2.jpg" }) {
        ...imageSettings
      }
      three: file(relativePath: { eq: "comics/TIHM_3.jpg" }) {
        ...imageSettings
      }
      four: file(relativePath: { eq: "comics/TIHM_4.jpg" }) {
        ...imageSettings
      }
    }
  `)

  return (
    <Section>
      <h2>This is Heavy Metal</h2>
      <div className="ImageWrapper">
        <Img fluid={data.one.childImageSharp.fluid} />
      </div>

      <div className="ImageWrapper">
        <Img fluid={data.two.childImageSharp.fluid} />
      </div>

      <div className="ImageWrapper">
        <Img fluid={data.three.childImageSharp.fluid} />
      </div>

      <div className="ImageWrapper">
        <Img fluid={data.four.childImageSharp.fluid} />
      </div>
    </Section>
  )
}
